import React, { useEffect, useState, useContext } from 'react';
import { Form, Input, Button, Upload, message, Spin } from 'antd'
import { UploadOutlined } from "@ant-design/icons";
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { SocketContext } from '../../../socket-context';
import axios from 'axios';



const LoginForm = ({ match, authStore, usersStore, projectStore, organizationStore, commonStore, history }) => {

  const socket = useContext(SocketContext);
  const [form] = Form.useForm();
  const [isLogin, setisLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successModelList, setSuccessModelList] = useState([])

  const handleAuthenticated = async function (msg) {
    const { type, data } = msg
  }

  const setupApiKeyConnect = async function () {
    if (!usersStore.currentUser.id && commonStore.checkToken()) {
      await usersStore.getCurrentUser()
    }
    if (usersStore.currentUser && usersStore.currentUser.id && usersStore.currentUser.apiKey) {
      if (!usersStore.joinAPIRoom) {
        usersStore.setjoinAPIRoom()
      }
    }

  }

  useEffect(() => {
    console.log('process.env.REACT_APP_IDENTIFIER', process.env.REACT_APP_IDENTIFIER);
    handleLogin({username: process.env.REACT_APP_IDENTIFIER, 
      password: process.env.REACT_APP_PASSWORD,
      publicKey: process.env.REACT_APP_PUBLICKEY})
  }, [])
  
  const handleLogin = values => {
    authStore.userLogin(values.username, values.password, values.publicKey, values.remember, history).then(function (data) {
      setupApiKeyConnect()
      setisLogin(true)
      socket.on("authenticated", msg => {
        handleAuthenticated(msg)
      })

      socket.emit("authenticate", {
        username: values.username,
        password: values.password,
        publicKey: values.publicKey,
        sesstionId: authStore.sesstionId
      });
    })
  }

  const CustomForm = props => {
    const getMimeOfFile = (filename) => {
      const ext = filename.split('.').pop()?.toLowerCase();
      let mime = ''
      switch (ext) {
        case 'pdf':
          mime = 'application/pdf'
          break;
        case 'png':
          mime = 'image/png'
          break;
        case 'jpe':
        case 'jpeg':
        case 'jpg':
          mime = 'image/jpeg'
          break;
        case 'gif':
          mime = 'image/gif'
          break;
        case 'json':
          mime = 'application/json'
          break;
        default:
          mime = 'application/octet-stream'
          break;
      }
      return mime
    }

    const handleUploadModel = async file => {
      console.log('file', file);
      console.log('file', file.name);
      try {
        setIsLoading(true)
        let result = await projectStore.generatePreSignedPutUrl({ fileName: file.name, projectId: process.env.REACT_APP_PROJECT_ID })

        const baseURL = new URL(result.url).origin + new URL(result.url).pathname;

        console.log(baseURL);
        let presignedS3Url = {
            url: result.url,
            fileName: result.file,
            fileData: file,
            selectedNode: null
        }

        const config = {
          method: 'put',
          url: presignedS3Url.url,
          headers: {
            'Content-Type': getMimeOfFile(presignedS3Url.fileData.name),
            'Access-Control-Allow-Origin': '*'
          },
          data: presignedS3Url.fileData,
        };

        //put presignedurl
        let presignedModel = await axios(config)
        .then(async (res) => {
          let model = {
            projectId: process.env.REACT_APP_PROJECT_ID,
            type: 'local',
            name: file.name,
            src: baseURL
          }
          await projectStore.createModel(model).then(res => {
            setIsLoading(false)
            message.success(`Upload and create model: ${file.name} successfully!`);
            setSuccessModelList(prev => prev.push(file.name))
            return true;
          }).catch(err => {console.log(err); setIsLoading(false);})
        })
        .catch(err => {console.log('put from s3', err); setIsLoading(false)})
      } catch (error) {
        setIsLoading(false)
        message.error('Error when upload model')
        return false
      }
      
  }

    const DragDropFileForm = () => {
      return (
        <Form.Item className='main-wrap'>
          <Spin tip="Uploading..." spinning={isLoading}>
            <Upload
              multiple={false}
              accept='.ifc'
              showUploadList={true}
              className='upload-model'
              beforeUpload={file => handleUploadModel(file)}
              >
                <span className='notice-text'>Only *.ifc file will be accepted</span>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <div className='model-list'>
              {
                successModelList?.length > 0 && successModelList.map((el, index) => <span key={index}>{el}</span>)
              }
            </div>
          </Spin>
        </Form.Item>
      )
    }


    return (
      <div>
          {authStore.isLogin ?
          <>
            <DragDropFileForm />
          </>
            :
            <p>Login...</p>
          }
      </div>

    )
  }

  return <CustomForm >

  </CustomForm>

}

export default withRouter(inject('authStore', 'usersStore', 'projectStore', 'commonStore')(observer(LoginForm)))