import styled from 'styled-components'
import {Row} from 'antd'

export const AuthPageWrapper = styled.div`
  display:flex;
  min-width: 100vw;
  min-height: 100vh;
  `
export const FormWrapper = styled.div`
  display: flex;
  padding: 40px;
  flex-basis: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  @media screen and (max-width: 480px) {
    width: calc(100vw - 60px);
  }
  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
  .ant-row.ant-form-item .ant-form-item-label>label {
    color: #919699;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 1.2;
  }
  .ant-row.ant-form-item .ant-input:not(textarea) {
    height: 40px;
    line-height: 40px;
  }
  .ant-btn {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .auth-form-login .remember .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.5;
    margin: 5px 0;
    .ant-checkbox-wrapper {
      margin-top: 0;
    }
    > * {
      margin: 5px 0 !important;
    }
  }
  .ant-checkbox-wrapper {
    line-height: 1.4;
    position: relative;
    padding-left: 25px;
    margin-top: 10px;
    .ant-checkbox {
      position: absolute;
      top: 2px;
      left: 0;
      + span {
        padding-left: 0;
      }
    }
  }
`
export const FormTitle = styled.h1`
  color: #191919;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 24px;
`
export const FormSubtitle = styled.p`
  color: rgb(102, 102, 102);
  font-size: 14px;
  margin-bottom: 25px;
`
export const NaviLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  word-break: break-all;
  margin: ${props => props.margin};
`
export const ProviderWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  .ant-btn {
    color: white;
    padding-left: 0;
    text-transform: none;
    background-color: #3d88ec;
    border: 1px solid #3d88ec;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    &:hover {
      background-color: #3d88ec;
      border: 1px solid #3d88ec;      
      color: white;
    }
  }
  .logo-wrapper {
    background-color: #fff;
    padding: 5px;
    height: 30px;
    margin-right: 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    img {
      height: 100%;
    }
  }
`
export const ViewpointToolWrapper = styled(Row)`
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  .dropdown-menu{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const IframeWrapper = styled.div`
    position: relative; /* Position 'absolute' will anchor to the closed element with position 'relative'. */
    display: inline-block;
    width: 100%;
    height: 100vh;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;

  .ant-modal-mask,
  .ant-modal-wrap {
    position: absolute;
  }
`

export const UploadWrapper = styled.div`
  max-height: 500px;
  .ant-upload.ant-upload-drag .ant-upload {
      padding: 16px;
  }
`

export const ProgressBodyStype = styled.div`
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 20px;
    .flex-row{display:flex;flex-flow:row nowrap}
    .row-center, .row-distribute {
        justify-content: space-between;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .flex-1 {
        flex-shrink: 1;
        flex-basis: auto;
        flex-grow: 1;
    }
    .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 29.5rem;
    }
    .text-muted {
        color: #8c8b96;
        font-size: 12px;
        text-align: center;
    }
    .flex-1 p {
        margin: 0;
    }
    .pr-1 {
      padding-right: 0.5rem!important;
    }
    .small {
      font-size: 1.2rem;
    }
    .text-bold {
      font-weight: 700;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
    .row-body{
      min-height: 5rem;
      border-bottom: 1px solid #e2e2e7;
      padding-top: 1.5rem!important;
      padding-bottom: 0.5rem!important;
      padding-left: 1rem!important;
      padding-right: 0.5rem!important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {    
      line-height: 2;     
    }
    .ant-collapse-extra {
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: -2px;
    }
    .ant-collapse-extra:hover {
      background-color: #e2e2e7;
    }

    .ant-btn-text:hover {
      background: #e2e2e7;
      border-color: #e2e2e7;
    }
`