import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import {
  AuthPageWrapper, FormWrapper} from './CustomStyled'
import LoginForm from './LoginForm'
import LoadingSpinner from '../../elements/LoadingSpinner'
import { inject, observer } from 'mobx-react'
import { Typography, Modal } from 'antd';


const AuthPage = ({ match, authStore, commonStore, usersStore, projectStore }) => {

  const xdUrl = `https://localhost:3000/auth/login?publicKey=${authStore.publicKey}&sesstionId=${authStore.sesstionId}`
  const authType = match.params.authType
  const { Text } = Typography;
  const checkAppLoading = () => {
    return !!(
      commonStore.isLoading ||
      authStore.isLoading ||
      usersStore.isLoading ||
      projectStore.isLoading
    )
  }

  const authFormInput = () => {
    return (
      <Fragment>
        <LoginForm />
      </Fragment>
    )
  }

  const authPageTitle = () => {
    switch (authType) {
      case 'login':
        return 'Login'
      case 'register':
        return 'Register'
      case 'forgot-password':
        return 'Forgot Password'
      default:
        return 'XD Demo upload model'
    }
  }

  return (
    <AuthPageWrapper>
      <Helmet>
        <title>{authPageTitle()}</title>
      </Helmet>
      <FormWrapper style={(authType === 'register') ? { marginTop: 150 } : {}}>
        {authFormInput()}
      </FormWrapper>
      {
        checkAppLoading()
          ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
          : null
      }
    </AuthPageWrapper>
  )

}

export default inject('authStore', 'commonStore', 'usersStore', 'projectStore')(observer(AuthPage))