import { observable, action, decorate, toJS } from 'mobx'
import { message } from 'antd'
// Request
import { ProjectRequest , ProjectLinkRequest} from '../requests'
import usersStore from './usersStore'
// StoresProjectDetailProjectDetail

class ProjectStore {

  projectList = []
  projectDetail = {}
  projectionList = []
  projectionDetail = []
  listProjectLink = []
  modelList = []
  showDataProject = false;
  treeLoading = true
  isSocketReady = false

  isLoading = false
 
  setLoadingProgress = state => {
    this.isLoading = state
  }

  async generatePreSignedPutUrl(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.generatePreSignedPutUrl(data).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }

  createModel = async (modelData) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.createModel(modelData)
        .then(info => {
          if (!info.data) reject(false)
          resolve(info.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


}

decorate(ProjectStore, {
  // Observables
  createModel: action,
  generatePreSignedPutUrl: action,
  isLoading: observable,
})

export default new ProjectStore()
