import { observable, action, decorate } from 'mobx'
import { message } from 'antd'
// import { apiUrl } from '../config'
import { UserRequest, TokenRequest, AuthRequest } from '../requests'
import commonStore from './commonStore'
import axios from 'axios'


class UsersStore {

  isLoading = false
  currentUser = {}
  currentUserMe = null
  editMode = false
  userAvatar = undefined
  users = []
  currentUserViewDetail = {}
  userViewEditing = {}
  isShowListUser = false
  joinAPIRoom = false 
  createUser = async (data) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      UserRequest.createUser(data).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
        .finally(() => this.setLoadingProgress(false))
    })

  }
  setjoinAPIRoom(){
    this.joinAPIRoom = true
  }
  updateUser = async (id, body) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      UserRequest.updateUser(id, body).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
        .finally(() => this.setLoadingProgress(false))
    })
  }

  setUserViewEditing = (data) => {
    this.userViewEditing = data
  }
  setCurrentUserViewDetail = (payload) => {
    this.currentUserViewDetail = payload;
  }

  getAllUsers = async () => {
    await UserRequest.getAllUsers()
      .then(res => {
        this.users = res.data
      })
      .catch(err => console.log(err))
  }
  getUserById = async (id) => {
    return new Promise((resolve, reject) => {
      UserRequest.getUserById(id).then(res => {
        resolve(res.data)
      })
        .catch(err => reject(err))

    })

  }


  setUserAvatar = (data) => {
    this.userAvatar = data
  }

  updateUserInfo(newInfo) {
    commonStore.setAppLoading(true)
    UserRequest.updateProfiles(newInfo)
      .then(response => {
        message.success(response.data.message)
        this.editMode = false
        this.setCurrentUser()
      })
      .catch(() => message.error(`An error occurred`))
      .finally(() => commonStore.setAppLoading(false))
  }

  userLogout() {
    this.currentUser = {}
    commonStore.clearToken()
    return Promise.resolve()
  }

  toggleEditMode(state) {
    this.editMode = state
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  async setCurrentUser(history) {
    this.setLoadingProgress(true)
    if (commonStore.checkToken()) {
      UserRequest.getCurrent()
        .then(response => {
          this.currentUser = response.data
          this.setLoadingProgress(false)
        })
        .catch(() => {
          commonStore.clearToken()
          this.setLoadingProgress(false)
          return (history) ? history.push('/auth/login') : null
        })
    } else {
      this.setLoadingProgress(false)
    }
  }

  async getCurrentUser(history) {
    this.setLoadingProgress(true)
    return UserRequest.getCurrent()
      .then(response => {
        this.currentUser = response.data
        this.setLoadingProgress(false)
      })
      .catch(() => {
        commonStore.clearToken()
        this.setLoadingProgress(false)
        return (history) ? history.push('/auth/login') : null
      })
  }

  createUserProfiles(data) {
    return UserRequest.createProfile(data)
      .then((response) => {
        this.currentUser.userprofile = response.data
        console.log(this.currentUser)
      })
      .catch(error => {
        console.log(error)
      })
  }

  // updateUserProfiles(profileid, data) {
  //   return UserRequest.updateProfile(profileid, data)
  //     .then((response) => {
  //       this.currentUser.userprofile = response.data
  //       console.log(this.currentUser.userprofile)
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }
  // updateUserProfileMe(data) {
  //   return UserRequest.updateProfileMe(data)
  //     .then((response) => {
  //       this.currentUserMe = response.data;



  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }
  updatProfileMes(newDataMe, profileid, data) {
    return axios.all([UserRequest.updateProfileMe(newDataMe), UserRequest.updateProfile(profileid, data)])
      .then(axios.spread((...res) => {
        // console.log(res[0])
        this.currentUser.userprofile = res[1].data
        this.currentUser.userme = res[0].data
      }))
      .catch(error => {
        console.log('catch test')
      })
  }

  deleteUser(tokenid) {
    this.setLoadingProgress(true)
    return UserRequest.delete(tokenid)
      .then((response) => {
        this.setLoadingProgress(false)
        return response
      })
      .catch(error => {
        this.setLoadingProgress(false)
        console.log(error)
        return error
      })
  }

  getValidToken = (tokenid) => {
    this.setLoadingProgress(true)
    return TokenRequest.getValidToken(tokenid)
      .then(response => {
        this.setLoadingProgress(false)
        return response
      })
      .catch(error => {
        console.log(error)
        this.setLoadingProgress(false)
        return error
      })
  }

  getPublicValidToken = (tokenid) => {
    this.setLoadingProgress(true)
    return TokenRequest.getPublicValidToken(tokenid)
      .then(response => {
        this.setLoadingProgress(false)
        return response
      })
      .catch(error => {
        console.log(error)
        this.setLoadingProgress(false)
        return error
      })
  }


  confirmDeleteMyAccount(data) {
    this.setLoadingProgress(true)
    return TokenRequest.sendMailConfirmDelete(data)
      .then((response) => {
        this.setLoadingProgress(false)
        if (response.data && response.data.error === "actuallysent") {
          message.success(`A confirmation email has been sent to you, please check your inbox`)
        } else {
          message.success(`Please check your email to confirm this delete your account request`)
        }
      })
      .catch(error => {
        this.setLoadingProgress(false)
        message.success(`Delete failed`)
      })
  }

  sendForgotPasswordEmail = async (data) => {
    return new Promise((resolve, reject) => {
      TokenRequest.sendForgotPasswordEmail(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  confirmChangePassword = async (data) => {
    return new Promise((resolve, reject) => {
      TokenRequest.confirmChangePassword(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changePasswordUser = async (data) => {
    return new Promise((resolve, reject) => {
      AuthRequest.changePasswordUser(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }



  updateUserAvatar = val => {
    this.setUserAvatar({ base64: val })
  }
}

decorate(UsersStore, {
  isLoading: observable,
  currentUser: observable,
  editMode: observable,
  setLoadingProgress: action,
  updateUserInfo: action,
  userLogout: action,
  toggleEditMode: action,
  setCurrentUser: action,
  createUserProfiles: action,
  updateUserProfiles: action,
  updateUserProfileMe: action,
  confirmDeleteMyAccount: action,
  updateUserAvatar: action,
  setUserAvatar: action,
  userAvatar: observable,
  currentUserMe: observable,
  updatProfileMes: action,
  getCurrentUser: action,

  users: observable,
  getAllUsers: action,
  currentUserViewDetail: observable,
  setCurrentUserViewDetail: action,
  isShowListUser: observable,
  userViewEditing: observable,
  setUserViewEditing: action,
  getUserById: action,
  deleteUser: action,
  createUser: action,
  updateUser: action,
  sendForgotPasswordEmail: action,
  getPublicValidToken: action,
  confirmChangePassword: action,
  joinAPIRoom:observable,
  setjoinAPIRoom:action,
})

export default new UsersStore()
