import { observable, action, decorate } from 'mobx'
import { message } from 'antd'
// Stores
import commonStore from './commonStore'
import usersStore from './usersStore'


// Requests
import { AuthRequest } from '../requests'
import uuid from 'uuid'
import settings from '../siteConfig'

class AuthStore {
  isLoading = false
  isLogin = false
  errors = undefined
  sesstionId = uuid()
  setLoadingProgress = state => {
    this.isLoading = state
  }
  publicKey = undefined

  setPublicKey = key => {
    this.publicKey = key
  }

  setIsLogin = status => {
    this.isLogin = status
  }

  userLogin(username, password, publicKey, remember, history) {
    this.setLoadingProgress(true)
    return AuthRequest.login(username, password)
      .then(response => {
        if (response.data.user.publicKey) {
          this.setPublicKey(response.data.user.publicKey)
          if (response.data.user.publicKey !== publicKey) {
            message.error("Public key is not correct. Please try again!")
          } else {
            this.setIsLogin(true)
          }
        } else {
          message.error("Public key is not correct. Please try again!")
        }
        commonStore.setRemember(remember)
        commonStore.setToken(response.data.jwt)

        if (commonStore.isRemember) {
          localStorage.setItem('jwt', response.data.jwt)
        } else {
          sessionStorage.setItem('jwt', response.data.jwt)
        }

        usersStore.setCurrentUser(history)
        return response.data
      })
      .catch(error => {
        message.error("Something went wrong !")
      })
      .finally(() => {
        this.setLoadingProgress(false)
      })
  }

}

decorate(AuthStore, {
  isLoading: observable,
  errors: observable,
  setLoadingProgress: action,
  userRegister: action,
  userLogin: action,
  userForgotPassword: action,
  checkResetPasswordToken: action,
  resetPassword: action,
  sesstionId: observable,
  setPublicKey: action,
  setIsLogin: action,
  publicKey: observable,
  isLogin: observable
})

export default new AuthStore()
