import { observable, action, decorate } from 'mobx'

class CommonStore {
  // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYTI5NWE5ZTE3NjIyMmQwY2UxN2IyYyIsImlhdCI6MTU3MzYzMzk2MiwiZXhwIjoxNTc2MjI1OTYyfQ.7EE-EsBPasCMO--xSOugZts3o2eoencz176KsDzYbDc'
  token = localStorage.getItem('jwt')
  isLoading = false
  isRemember = true
  isSidebarCollapse = false
  appTheme = {
    name: 'default',
    solidColor: '#F26524',
    solidLightColor: '#ecf9f6',
    gradientColor: 'linear-gradient(167.51deg, #f26524 24.37%, #f26524 78.07%)',
    shadowColor: '0 2px 10px rgba(46,207,148,0.6)',
  }
  cameraPosition = undefined
  showMainDrawer = false
  showLicenseDrawer = false
  showOrganizationPageDrawer = false
  showUserSettingPageDrawer = false
  currentPage = ''
  currentMainDrawerPanel = 'cityDemo'
  buttonSize = 'default'
  buttonSizeNormal = 'default'
  deviceId = undefined;
  devices = [];
  showCalibrationManual = false;
  cameraCesium = undefined
  enableShadows = false;
  ambientViewModel = {
    intensity: 1.0,
    bias: 0.4,
    lengthCap: 0.3,
    stepSize: 2,
    frustumLength: 100,
    blurStepSize: 2
  };
  setAmbientViewModel = v => {
    Object.assign(this.ambientViewModel, v)
  }
  setenableShadows(b) {
    this.enableShadows = b;
  }
  verticalCalibration = this.getLocalVerticalCalibration();
  horizontalCalibration = this.getLocalHorizontalCalibration();
  setShowCalibrationManual(isShow) {
    this.showCalibrationManual = isShow
  }
  enableHighAccuracy = true;
  setenableHighAccuracy(b) {
    this.enableHighAccuracy = b;
  }
  openSensorSetting = false;
  setopenSensorSetting(c) {
    this.openSensorSetting = c;
  }
  setcameraCesium(c) {
    this.cameraCesium = c;
  }
  setCameraPossition(p) {
    this.cameraPosition = p;
  }

  setShowLicenseDrawer = status => {
    this.showLicenseDrawer = status
  }

  getLocalVerticalCalibration() {
    const value = localStorage.getItem('verticalCalibration');
    if (!isNaN(value)) return Number(value);
    return 0;
  }
  getLocalHorizontalCalibration() {
    const value = localStorage.getItem('horizontalCalibration');
    if (!isNaN(value)) return Number(value);
    return 0;
  }
  setVerticalCalibration(value) {
    this.verticalCalibration = value
    localStorage.setItem('verticalCalibration', this.verticalCalibration)
  }
  setHorizontalCalibration(value) {
    this.horizontalCalibration = value
    localStorage.setItem('horizontalCalibration', this.horizontalCalibration)
  }
  setDeviceId(deviceId) {
    this.deviceId = deviceId
  }

  setDevices(devices) {
    this.devices = devices
  }

  setToken(token) {
    this.token = token
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  setCurrentMainDrawerPanel(currentPanel) {
    this.currentMainDrawerPanel = currentPanel
  }

  setShowMainDrawer(isShow) {
    this.showMainDrawer = isShow
  }

  setShowOrganizationPageDrawer(isShow) {
    this.showOrganizationPageDrawer = isShow
  }

  setShowUserSettingPageDrawer(isShow) {
    this.showUserSettingPageDrawer = isShow
  }


  setCurrentPage(pageName) {
    this.currentPage = pageName
  }

  checkToken() {
    if (this.token) return true
    return !!(localStorage.jwt || sessionStorage.jwt)
  }

  clearToken() {
    localStorage.clear()
    sessionStorage.clear()
  }

  setRemember(isRemember) {
    this.isRemember = isRemember
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }
  timeSliderVisible = false
  toggleTimeSliderVisible = (f) => {    
    if (f !== undefined) {
      this.timeSliderVisible = f
    } else {
      this.timeSliderVisible = !this.timeSliderVisible
    }
   
  }
  setTheme(color) {
    switch (color) {
      case 'red':
        this.appTheme.name = 'red'
        this.appTheme.solidColor = 'rgb(244, 67, 54)'
        this.appTheme.solidLightColor = 'rgb(254, 237, 235)'
        this.appTheme.gradientColor =
          'linear-gradient(108.84deg, #F77062 0%, #FE5196 100%)'
        this.appTheme.shadowColor = '0 2px 10px rgba(254, 81, 150, 0.5)'
        break
      default:
        this.appTheme.name = 'green'
        this.appTheme.solidColor = '#222'
        this.appTheme.solidLightColor = '#fff'
        this.appTheme.gradientColor =
          'linear-gradient(167.51deg, #fff 24.37%, #fff 78.07%)'
        this.appTheme.shadowColor = '0 2px 10px rgba(46,207,148,0.6)'
        break
    }
  }
}

decorate(CommonStore, {
  token: observable,
  setToken: action,
  checkToken: action,
  clearToken: action,

  showMainDrawer: observable,
  setShowMainDrawer: action,

  showOrganizationPageDrawer: observable,
  setShowOrganizationPageDrawer: action,

  showUserSettingPageDrawer: observable,
  setShowUserSettingPageDrawer: action,

  currentPage: observable,
  setCurrentPage: action,

  currentMainDrawerPanel: observable,
  setCurrentMainDrawerPanel: action,

  isLoading: observable,
  setLoadingProgress: action,

  isRemember: observable,
  setRemember: action,

  appTheme: observable,
  setTheme: action,
  deviceId: observable,
  devices: observable,
  setDeviceId: action,
  setDevices: action,
  showCalibrationManual: observable,
  verticalCalibration: observable,
  horizontalCalibration: observable,
  setShowCalibrationManual: action,

  setVerticalCalibration: action,
  setHorizontalCalibration: action,
  setcameraCesium: action,
  cameraCesium: observable,

  cameraPosition: observable,
  setCameraPossition: action,
  enableHighAccuracy: observable,
  setenableHighAccuracy: action,
  openSensorSetting: observable,
  setopenSensorSetting: action,
  enableShadows: observable,
  setenableShadows: action,
  ambientViewModel: observable,
  setAmbientViewModel: action,

  showLicenseDrawer: observable,
  setShowLicenseDrawer: action,
})

export default new CommonStore()
