import { observable, action, decorate, toJS } from 'mobx'
// import { message } from 'antd'
// import { reloadUrl, getModelUrBySrc, extractHostname } from '../components/helper/CesiumUtils'

class UIStore {
  showIFCSetting = false
  showAmbientOccSetting = false
  requestRender = true
  showObjectInfo = false
  showAttrPanel = false
  showUndergroundSetting = false
  setRequestRender = b => {
    this.requestRender = b
  }

  setShowIFCSetting = b => {
    this.showIFCSetting = b
  }

  setShowAmbientOccSetting = b => {
    this.showAmbientOccSetting = b;
  }

  setShowObjectInfo = b => {
    this.showObjectInfo = b
  }

  setShowAttrPanel = b => {
    this.showAttrPanel = b
  }

  setShowUndergroundSetting = b => {
    this.showUndergroundSetting = b
  }

}

decorate(UIStore, {
  showIFCSetting: observable,
  setShowIFCSetting: action,
  requestRender: observable,
  setRequestRender: action,

  showObjectInfo: observable,
  setShowObjectInfo: action,
  showAmbientOccSetting: observable,
  setShowAmbientOccSetting: action,
  showAttrPanel: observable,
  setShowAttrPanel: action,
  setShowUndergroundSetting: action,
  showUndergroundSetting : observable
})

export default new UIStore()
